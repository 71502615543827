import ear from './ear.svg';
import './App.css';

function App() {
    return (
        <div className="App">
            <header className="App-header">
                <img src={ear} className="App-logo" alt="logo" />
                <a className="App-link" href="/admin">
                    Admin Dashboard
                </a>
            </header>
        </div>
    );
}

export default App;
